
import {ref} from 'vue';
import { RouterLink } from 'vue-router'
import type { MenuOption } from 'naive-ui'
import { h } from 'vue'

const menuOptions: MenuOption[] = [
  {
    label: () =>
        h(
            RouterLink,
            {
              to: {
                name: 'Home',
                // path: '/home',
                // params: {
                //   lang: 'zh-CN'
                // }
              }
            },
            { default: () => 'Home' }
        ),
    key: 'Home',
    // icon: renderIcon(HomeIcon)
  },
  {
    label: () =>
        h(
            RouterLink,
            {
              to: {
                name: 'FmtConvert',
              }
            },
            { default: () => '格式转换' }
        ),
    key: 'FmtConvert',
    // icon: renderIcon(HomeIcon)
  },
  {
    label: () =>
        // h(
        //     RouterLink,
        //     {
        //       to: {
        //         name: 'Easyweb',
        //       }
        //     },
        //     { default: () => 'Easyweb' }
        // ),
        h(
            'a',
            {
              href: 'https://nisus-liu.gitee.io/easyweb-document',
              target: '_blank',
              rel: 'noopenner noreferrer'
            },
            'Easyweb'
        ),
    key: 'Easyweb',
    // icon: renderIcon(HomeIcon)
  },
  {
    label: () =>
        h(
            RouterLink,
            {
              to: {
                path: '/about'
              }
            },
            { default: () => 'About' }
        ),
    key: 'About',
    // icon: renderIcon(WorkIcon)
  }
]

export default {
  name: "BasicLayout",
  data() {
    return {
      collapsed: ref<boolean>(false),
      selectedKeys: ref<string[]>(['home']),
      menuOptions,
    };
  },
}
